import api from '@admin/api/service';

export default {
  getEligibleProperties(key) {
    return api.get(
      `singgahsini/api/income/approval-property-disbursement/manual/eligible-property?key=${key}`
    );
  },

  postManualDisbursement(data) {
    return api.post(
      'singgahsini/api/income/approval-property-disbursement/manual',
      data
    );
  },
};
