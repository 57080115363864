import dayjs from 'dayjs';
import 'dayjs/locale/id';
import diffDateInHours from './diffDateInHours';

dayjs.locale('id');

// Convert
// Date Wed Jun 30 2021 11:54:01 GMT+0700 (Western Indonesia Time)
// Into
// 2021-06-30
export const dateFormatterToSend = value => {
  if (!dayjs(value).isValid()) return '';

  const formatted = dayjs(value).format('YYYY-MM-DD');
  return formatted;
};

// Convert
// 2021-01-01 13:29:10
// Into
// 01 Jan 2021
export const dateFormatterToDisplay = (value, format = 'DD MMM YYYY') => {
  if (!dayjs(value).isValid()) return '';

  const formatted = dayjs(value).format(format);
  return formatted;
};

// Convert
// 10000
// Into
// Rp10.000
export const rupiahFormatter = value => {
  const valueNumber = Number(value) || 0;
  const valueString = Math.abs(valueNumber).toString();
  const isNegative = valueNumber < 0;

  return `${isNegative ? '-' : ''}Rp${valueString
    .replace(/(?!\.)-\D/g, '')
    .replace(/(?:\..*)\./g, '')
    .replace(/(?:\.\d\d).*/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
};

// Convert
// 1000000
// Into
// 1.000.000
export const currencyFormatter = value =>
  value
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const truncateEllipsis = (string, len) => {
  const allString = string.toString();

  if (allString.length > len) {
    return `${allString.substring(0, len).trim()}...`;
  }
  return allString;
};

export const dayFormatterTodayYesterday = messageTime => {
  const now = new Date();
  const dateMessage = new Date(messageTime);
  const diffInHour = diffDateInHours(dateMessage, now);
  const isSameDay = diffInHour <= 24;
  const isYesterday = diffInHour > 24 && diffInHour <= 48;
  const dayFormat = dateMessage.toLocaleString('id', {
    month: 'short',
    day: 'numeric',
  });

  if (isSameDay) {
    return 'Hari ini';
  } else if (isYesterday) {
    return 'Kemarin';
  }

  return dayFormat;
};

export const onlyNumber = value => value.replace(/\D/g, '');

export const cleanNumber = value =>
  Number(String(value).replace(/\.?\D?/g, ''));

export { dayjs };
