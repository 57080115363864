<template>
  <div class="add-manual-transfer">
    <page-header
      :back-to="{ name: 'payment-disbursement-approval' }"
      title="Transfer Manual Pendapatan Pemilik"
      class="mb-32"
    />

    <bg-grid>
      <bg-grid-item :col="6">
        <bg-field label="Nama Properti" class="mb-32">
          <bg-select
            :value="form.property_id"
            :options="propertyOptions"
            placeholder="Pilih nama properti"
            searchable
            @search-input="handleSearchProperty"
            @change="handlePropertySelected"
          ></bg-select>
        </bg-field>

        <bg-grid>
          <bg-grid-item :col="12">
            <bg-list-item title="Model Kerja Sama">
              {{ partnershipModel || '-' }}
            </bg-list-item>
          </bg-grid-item>
          <bg-grid-item :col="12">
            <bg-list-item title="Detail Rekening">
              <template v-if="Object.keys(selectedProperty).length">
                <p>{{ selectedProperty.account_name }}</p>
                <p>{{ selectedProperty.account_number }}</p>
                <p>{{ selectedProperty.account_bank }}</p>
              </template>
              <span v-else>-</span>
            </bg-list-item>
          </bg-grid-item>
        </bg-grid>
      </bg-grid-item>

      <bg-grid-item :col="6">
        <bg-field label="Jadwal Transfer" class="mb-32">
          <bg-datepicker
            v-model="form.scheduled_date"
            placeholder="Masukkan jadwal transfer"
          />
        </bg-field>
        <bg-grid>
          <bg-grid-item :col="12">
            <bg-list-item title="Cut Off">{{ cutOff }}</bg-list-item>
          </bg-grid-item>
          <bg-grid-item :col="12">
            <bg-list-item title="Total Transfer Pendapatan ke Pemilik">
              Rp0
            </bg-list-item>
          </bg-grid-item>
        </bg-grid>
      </bg-grid-item>

      <bg-grid-item :col="12">
        <bg-button
          size="lg"
          variant="primary"
          :disabled="isButtonAddDisabled"
          :loading="isAddLoading"
          @click="handleAddManualTransfer"
        >
          Tambahkan
        </bg-button>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgField,
  BgSelect,
  BgDatepicker,
  BgButton,
  BgListItem,
} from 'bangul-vue';
import PageHeader from '@admin_molecules/PageHeader';
import debounce from 'Utils/debounce';
import manualTransferApi from '@admin_endpoints/disbursement-manual-transfer';
import { dateFormatterToDisplay } from 'Utils/formatter';

const SEARCH_DELAY_TIME = 500;

export default {
  name: 'FormManualTransfer',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSelect,
    BgDatepicker,
    BgListItem,
    BgButton,
    PageHeader,
  },

  data() {
    return {
      form: {
        property_id: '',
        scheduled_date: null,
      },
      isSearchLoading: false,
      isAddLoading: false,
      propertySearchResults: [],
      selectedProperty: {},
      propertyOptions: [],
    };
  },

  computed: {
    partnershipModel() {
      const { revenue_model_name: basic, jp, adp } = this.selectedProperty;
      return [basic, jp, adp].filter(item => item).join(' + ');
    },
    cutOff() {
      if (!Object.keys(this.selectedProperty).length) {
        return '-';
      }

      const startDate = dateFormatterToDisplay(
        this.selectedProperty.cutoff_start,
        'DD/MM/YYYY'
      );
      const endDate = dateFormatterToDisplay(
        this.selectedProperty.cutoff_end,
        'DD/MM/YYYY'
      );

      return `${startDate} - ${endDate}`;
    },
    isButtonAddDisabled() {
      const { adp, jp, revenue_model_name: model } = this.selectedProperty;
      const areFormsEmpty = Object.entries(this.form).some(([_, val]) => !val);
      return areFormsEmpty || !model || !!adp || !!jp;
    },
  },

  methods: {
    // eslint-disable-next-line
    handleSearchProperty: debounce(async function (src) {
      this.isSearchLoading = true;

      try {
        const {
          data: { data: properties },
        } = await manualTransferApi.getEligibleProperties(src);
        this.propertySearchResults = properties;
        this.propertyOptions = properties.map(property => ({
          label: property.property_name,
          val: property.property_id,
        }));
      } catch (error) {
        const errorMessage = this.grabErrorMessage(
          error,
          'Terjadi kesalahan saat mencari properti.'
        );

        this.$toast.show(errorMessage);
      } finally {
        this.isSearchLoading = false;
      }
    }, SEARCH_DELAY_TIME),

    handlePropertySelected(selectedPropertyId) {
      this.form.property_id = selectedPropertyId;
      const selectedProperty = this.propertySearchResults.find(
        property => property.property_id === selectedPropertyId
      );

      /* istanbul ignore next */
      if (selectedProperty) {
        this.selectedProperty = selectedProperty;
        this.form.scheduled_date = new Date(selectedProperty.scheduled_date);
      }
    },

    grabErrorMessage(error, defaultMessage = '') {
      if (!error.response) return defaultMessage;

      return error.response.data.issue?.message || defaultMessage;
    },

    async handleAddManualTransfer() {
      this.isAddLoading = true;
      const data = {
        property_id: this.form.property_id,
        scheduled_date: dateFormatterToDisplay(
          this.form.scheduled_date,
          'YYYY-MM-DD'
        ),
        contract_id: this.selectedProperty.contract_id,
      };

      try {
        const { data: res } = await manualTransferApi.postManualDisbursement(
          data
        );

        if (res.data?.approval_id) {
          this.$router.replace({
            name: 'disbursement.detail-transfer',
            params: {
              approval_id: res.data.approval_id,
            },
          });
        } else {
          this.$router.replace({ name: 'payment-disbursement-approval' });
        }
      } catch (error) {
        const defaultMessage =
          'Terjadi kesalahan saat menambahkan transfer manual.';
        const details = error?.response.data.issue?.details || [];

        this.$toast.show(details[0] || defaultMessage);
        console.error(error);
      } finally {
        this.isAddLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" src="./FormManualTransfer.scss" scoped></style>
