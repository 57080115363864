<template>
  <header class="page-header">
    <router-link
      v-if="backTo"
      :to="backTo"
      class="mr-32"
      data-testid="route-back-btn"
    >
      <button class="page-header__back">
        <bg-icon name="back" size="lg" />
      </button>
    </router-link>
    <bg-text size="heading-2">
      {{ title }}
    </bg-text>
  </header>
</template>

<script>
import { BgText, BgIcon } from 'bangul-vue';

export default {
  name: 'PageHeader',

  components: { BgText, BgIcon },

  props: {
    backTo: {
      type: [String, Object],
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" src="./PageHeader.scss" scoped></style>
