export default function (date1, date2) {
  const MILLISECOND_IN_SECOND = 1000;
  const SECOND_IN_HOUR = 3600;

  return Math.abs(
    Math.round(
    (date2.getTime() - date1.getTime()) /
      MILLISECOND_IN_SECOND /
      SECOND_IN_HOUR
    )
  );
};
